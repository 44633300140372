import React, { useContext } from "react";
import Todo from "./Todo";
import TodoContext from "../Context/TodoContext";

const TodoList = () => {
  let stateData = useContext(TodoContext);

  let start = (stateData.currentPage - 1) * stateData.perPage;
  let end = stateData.currentPage * stateData.perPage;
  return (
    <div className="container">
      <ul
        className="list-group todo-list text-center"
        style={{ listStyle: "none" }}
      >
        {stateData.filteredTodos.slice(start, end).map((todo) => {
          return <Todo key={todo.id} todo={todo} />;
        })}
      </ul>
    </div>
  );
};

export default TodoList;
