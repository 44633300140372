import { useReducer } from "react";
import Context from "./TodoContext";
import reducer from "./TodoReducer";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  SET_TODOS,
  SET_STATUS,
  SET_FILTERED_TODOS,
  SET_CURRENT_PAGE,
} from "./Types";

const TodoState = (props) => {
  let initialState = {
    todos: [],
    status: "All",
    filteredTodos: [],
    perPage: 5,
    currentPage: 1,
  };

  let [state, dispatch] = useReducer(reducer, initialState);

  const addTodos = (e) => {
    dispatch({ type: SET_TODOS, payload: [e, ...state.todos] });
  };

  const removeTodos = (e) => {
    dispatch({ type: SET_TODOS, payload: e });
    dispatch({ type: SET_FILTERED_TODOS, payload: e });
  };

  const completeTodos = (i) => {
    let t = state.todos.map((item) => {
      return item.id === i ? { ...item, completed: !item.completed } : item;
    });
    dispatch({ type: SET_TODOS, payload: t });
  };

  const changeStatus = (e) => {
    dispatch({ type: SET_STATUS, payload: e });
    dispatch({ type: SET_CURRENT_PAGE, payload: 1 });
  };

  const modifyTodoText = (i, t) => {
    let text = state.todos.map((item) =>
      item.id === i ? { ...item, text: t } : item
    );
    dispatch({ type: SET_TODOS, payload: text });
  };

  const modifyTodoEdit = (i) => {
    let edit = state.todos.map((item) =>
      item.id === i ? { ...item, edit: !item.edit } : item
    );
    dispatch({ type: SET_TODOS, payload: edit });
  };

  const fetchData = async () => {
    try {
      let res = await axios.get("https://jsonplaceholder.typicode.com/todos");
      let data = res.data.map((todo) => {
        return {
          completed: todo.completed,
          text: todo.title,
          id: uuidv4(),
          edit: false,
        };
      });
      dispatch({ type: SET_TODOS, payload: data });
      dispatch({ type: SET_FILTERED_TODOS, payload: data });
    } catch (err) {
      console.log(err);
    }
  };

  const filterTodos = (e) => {
    let f = state.todos.filter((todo) => {
      if (e === "Completed") {
        return todo.completed;
      } else if (e === "Uncompleted") {
        return !todo.completed;
      } else {
        return true;
      }
    });
    dispatch({ type: SET_FILTERED_TODOS, payload: f });
  };

  const setCurrentPage = (e) => {
    dispatch({ type: SET_CURRENT_PAGE, payload: e });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        addTodos,
        removeTodos,
        completeTodos,
        changeStatus,
        modifyTodoText,
        modifyTodoEdit,
        fetchData,
        filterTodos,
        setCurrentPage,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default TodoState;
