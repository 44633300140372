import React, { useContext } from "react";
import TodoContext from "../Context/TodoContext";

const Todo = (props) => {
  let stateData = useContext(TodoContext);

  const deleteHandler = () => {
    stateData.removeTodos(
      stateData.filteredTodos.filter((e) => e.id !== props.todo.id)
    );
  };

  const completeHandler = () => {
    stateData.completeTodos(props.todo.id);
  };

  const editHandler = () => {
    stateData.modifyTodoEdit(props.todo.id);
  };

  const handleChange = (e) => {
    stateData.modifyTodoText(props.todo.id, e.target.value);
  };

  return (
    <li
      className={`todo list-group-element${
        props.todo.completed ? " text-muted" : ""
      }`}
      style={props.todo.completed ? { textDecoration: "line-through" } : {}}
    >
      {!props.todo.edit ? (
        <h3>
          {props.todo.text}
          {"   "}
          <span>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={completeHandler}
              >
                <span class="material-icons-outlined">done_outline</span>
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteHandler}
              >
                <span class="material-icons-outlined">delete</span>
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={editHandler}
              >
                <span
                  style={{ color: "white" }}
                  class="material-icons-outlined"
                >
                  edit
                </span>
              </button>
            </div>
          </span>
        </h3>
      ) : (
        <form>
          <div className="row">
            <div className="mb-3 col-11">
              <input
                type="text"
                className="form-control"
                value={props.todo.text}
                style={{ textDecoration: "none" }}
                onChange={handleChange}
              />
            </div>
            <div className="col-1">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={editHandler}
              >
                <span class="material-icons-outlined">task_alt</span>
              </button>
            </div>
          </div>
        </form>
      )}
    </li>
  );
};

export default Todo;
