import React, { useContext } from "react";
import TodoContext from "../Context/TodoContext";
import { v4 as uuidv4 } from "uuid";

const Pagination = () => {
  let stateData = useContext(TodoContext);

  let pages = Math.ceil(stateData.filteredTodos.length / stateData.perPage);

  return (
    <nav aria-label="Page navigation example" className="container">
      <ul className="pagination justify-content-center d-flex flex-wrap">
        <li key={uuidv4()} className="page-item">
          <button
            onClick={() => {
              stateData.currentPage !== 1 &&
                stateData.setCurrentPage(stateData.currentPage - 1);
            }}
            className="page-link"
          >
            Previous
          </button>
        </li>
        {[...Array(pages)].map((item, index) => {
          return (
            <li
              key={uuidv4()}
              className={`page-item${
                stateData.currentPage === index + 1 && " active"
              }`}
            >
              <button
                onClick={() => {
                  stateData.setCurrentPage(index + 1);
                }}
                className="page-link"
              >
                {index + 1}
              </button>
            </li>
          );
        })}
        <li key={uuidv4()} className="page-item">
          <button
            onClick={() => {
              stateData.currentPage !== pages &&
                stateData.setCurrentPage(stateData.currentPage + 1);
            }}
            className="page-link"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
