import {
  SET_INPUT_TEXT,
  SET_TODOS,
  SET_STATUS,
  SET_FILTERED_TODOS,
  SET_PER_PAGE,
  SET_CURRENT_PAGE,
} from "./Types";

let reducer = (state, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_INPUT_TEXT:
      return {
        ...state,
        inputText: payload,
      };
    case SET_TODOS:
      return {
        ...state,
        todos: payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case SET_FILTERED_TODOS:
      return {
        ...state,
        filteredTodos: payload,
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    default:
      return state;
  }
};

export default reducer;
