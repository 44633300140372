import { createContext } from "react";

let TodoContext = createContext({
  inputText: "",
  todos: [],
  status: "All",
  filteredTodos: [],
  perPage: 5,
  setCurrentPage: 1,
});

export default TodoContext;
