import React, { useEffect, useContext } from "react";
import Header from "./Components/Header";
import Form from "./Components/Form";
import TodoList from "./Components/TodoList";
import Pagination from "./Components/Pagination";
import TodoContext from "./Context/TodoContext";
import TodoState from "./Context/TodoState";
const App = () => {
  let stateData = useContext(TodoContext);

  useEffect(() => {
    stateData.fetchData();
  }, []);

  useEffect(() => {
    stateData.filterTodos(stateData.status);
  }, [stateData.todos, stateData.status]);

  return (
    <div className="text-light">
      <Header />
      <Form />
      <br />
      <TodoList />
      <br />
      <br />
      <Pagination />
    </div>
  );
};

const AppContext = () => {
  return (
    <TodoState>
      <App />
    </TodoState>
  );
};

export default AppContext;
