import React, { useContext, useState } from "react";
import TodoContext from "../Context/TodoContext";
import { v4 as uuidv4 } from "uuid";

const Form = () => {
  let stateData = useContext(TodoContext);
  let [inputText, setInputText] = useState("");

  const inputTextHandler = (e) => {
    setInputText(e.target.value);
  };

  const submitTodoHandler = (e) => {
    e.preventDefault();
    stateData.addTodos({
      text: inputText,
      completed: false,
      edit: false,
      id: uuidv4(),
    });
    setInputText("");
  };

  const statusHandler = (e) => {
    stateData.changeStatus(e.target.innerText);
  };

  return (
    <form className="container">
      <div className="row">
        <div className="mb-3 col-9">
          <label htmlFor="addTodo" className="form-label">
            Add a Task
          </label>
          <input
            onChange={inputTextHandler}
            type="text"
            className="form-control"
            id="addTodo"
            value={inputText}
          />
        </div>
        <div className="dropdown col-2">
          <br />
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ marginTop: "8px" }}
          >
            {stateData.status}
          </button>
          <ul className="dropdown-menu">
            <li className="dropdown-item" onClick={statusHandler}>
              All
            </li>
            <li className="dropdown-item" onClick={statusHandler}>
              Completed
            </li>
            <li className="dropdown-item" onClick={statusHandler}>
              Uncompleted
            </li>
          </ul>
        </div>
      </div>
      <button
        onClick={submitTodoHandler}
        type="submit"
        className="btn btn-primary"
      >
        Add
      </button>
    </form>
  );
};

export default Form;
